.connection{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    display: grid;
    height: 400px;
    margin: 8px;
    color:#666;
}

.connection label{
    align-self: flex-start;
}