body {
	margin: 0px;
	width: 100vw;
	height: 100vh;
	overflow: auto;
	position: fixed;
}

.App {
	/* opacity: 0.8; */
	text-align: center;
	position: absolute;
	width: 100% !important;
	height: 100% !important;
	/* background-color: black; */
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	display: flex;
	min-height: 100vh;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	background-color: #ffffff;
	font-size: calc(10px + 2vmin);
}

.App-link {
	color: #61dafb;
}
