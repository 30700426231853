.reconnectingContent {
  /* justify-content: center;
    border-radius: 15px;
    position: fixed;
    display: grid;
    height: 480px;
    width: 100%;
	z-index: 2; */
  width: 100%;
  height: 100%;
  opacity: 0.6;
  display: flex;
  transition: 5ms;
  position: absolute;
  justify-content: center;
}

.reconnectingMessage {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  background-color: rgba(255, 133, 39, 0.8);
  text-align: center;
  align-self: center;
  border-radius: 6px;
  color: white;
  padding: 8px;
  margin: 8px;
}
