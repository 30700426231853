.messageContainer{
    flex-direction: column;
    display: flex;
}

.messageBox{
    border-radius: 10px 10px 0px 10px;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(2.5px);
    align-self: center;
    display: grid;
    height: 262px;
    width: 299px;
    margin:15px;
}

.messageControl{
    display: flex;
    width: 100%;
}

.messageContent{
    top:2%;
    flex: 1 1 auto;
	height: auto;
	min-width: 20%;
    overflow: hidden;
	min-height: 10em;
	overflow-y: scroll;
	position: relative;
	padding: 2px 4px 2px 4px;
	color: rgba(255, 255, 255, 0.8);
}

.messageControl textarea{
    border-color: rgba(0, 0, 0, 0.7);
    color: rgba(255, 255, 255, 0.8);
    background: rgba(0, 0, 0, 0.7);
    font: italic bold Georgia, serif;
    backdrop-filter: blur(2.5px);
    border-radius: 8px;
    font-size: large;
    margin: auto;
    width: 192px;
    height: 42px;
}

.messageText{
	clear: both;
	width: auto;
	margin: 8px 0;
    font-size: medium;
	position: relative;
	padding: 6px 10px 7px;
	border-radius: 10px 10px 10px 0;
	font-family: Arial, Helvetica, sans-serif;
}

.analystMessage{
    float: left;
	margin-right: 12px;
	backdrop-filter: blur(2.5px);
	border-radius: 8px 8px 8px 0px;
	background: rgba(0, 0, 0, 0.7);
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.insuredMessage{
    float: right;
	margin-left: 12px;
	background-color: #ff8527cc;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

@media screen and (min-aspect-ratio: 13/9) { 
    /* landscape mode */ 
    .messageContainer{
        flex-direction: row-reverse;
        display: flex;
    }
        
}