.errorPermissionContent{
    justify-content: center;
    border-radius: 15px;
    position: fixed;
    display: grid;
    height: 480px;
    width: 100vw;
	z-index: 2;
}

.errorPermissionMessage{
	font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: center;
    align-self: center;
    margin: 10vw;
}