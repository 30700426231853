 .logo {
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  max-width: 120px;
  z-index: 3;
  max-height: 50px;
  min-height: 50px;
  }
  
  @media screen and (orientation: landscape) {
    .logo{
      margin-left: 0px;
      margin-top: 0px;
      max-width: 100px;
    }
  }