.welcomeContent{
    background-color: #efefef;
    justify-content: center;
    border-radius: 15px;
    position: relative;
    max-height: 450px;
    display: grid;
    min-height: 380px;
    max-width: 340px;
    min-width: 290px;
    bottom: 8vh;
}

.welcomeMessage{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: center;
    align-self: center;
    margin: 8px;
    color:#666;

}

@media screen and (min-aspect-ratio: 13/9) { 
    /* landscape mode */ 
    .welcomeContent{
        background-color: #efefef;
        justify-content: center;
        border-radius: 15px;
        position: relative;
        max-height: 280px;
        min-height: 225px;
        max-width: 350px;
        display: grid;
        bottom: 30px;
    }
}

@media screen and (max-aspect-ratio: 13/9) { /* portrait mode */ }

