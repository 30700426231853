.local-media {
  bottom: 1em;
  overflow: hidden;
  position: absolute;
  width: 100% !important;
  height: 100% !important;
}

.local-media video {
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.local-media-reconnecting {
  width: 100%;
  height: 100%;
  opacity: 0.6;
  transition: 5ms;
}

.local-media-reconnected {
  width: 100%;
  height: 100%;
  transition: 5ms;
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */

  @media (orientation: portrait) {
    @media screen and (max-height: 750px) {
      .local-media video {
        /* transform: scale(1,1.1) translateY(-5%);      */
        position: absolute;
        object-fit: fill;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
      }
    }
  }
}

@media (orientation: portrait) {
  @media screen and (max-height: 600px) {
    .local-media video {
      /* transform: scale(1,1.1) translateY(-5%);      */
      position: absolute;
      object-fit: fill;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  }
}

.remote-media {
  border-radius: 8px !important;
  position: absolute;
  overflow: hidden;
  height: 84px;
  width: 116px;
  right: 18px;
  z-index: 1;
  top: 20px;
}

.remote-media video {
  width: 100%;
  left: 0;
  top: 0;
}

@media screen and (min-aspect-ratio: 13/9) {
  .remote-media {
    border-radius: 8px !important;
    position: absolute;
    overflow: hidden;
    height: 84px;
    width: 110px;
    left: 18px;
    z-index: 1;
    top: 60px;
  }
}
