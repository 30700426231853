.controls {
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
}

.flipContent {
  top: 40%;
  left: 50%;
  height: 80px;
  width: 100px;
  display: flex;
  position: fixed;
  background: white url("../assets/loading.gif") center no-repeat;
  z-index: 1;
}

.disconnectedContent {
  justify-content: center;
  border-radius: 15px;
  position: fixed;
  display: grid;
  height: 480px;
  width: 100%;
  z-index: 2;
}

.errorMessage {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  text-align: center;
  align-self: center;
  margin: 8px;
}

.errorContent {
  justify-content: center;
  border-radius: 15px;
  position: fixed;
  display: grid;
  height: 480px;
  width: 100%;
  z-index: 2;
}

.disconnectedMessage {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  text-align: center;
  align-self: center;
  margin: 8px;
}
