.chat-container{
	display: flex;
}

.buttons {
	width: 100%;
	display: flex;
	justify-content: space-evenly;
}

.navButton {
	background: #fff !important;
	margin: 0px 5px !important;
	color: gray !important;
}

@media screen and (min-aspect-ratio: 13/9) { 
	.buttons-landscape-active {
		right: 0px;
		width: 40%;
		display: flex;
		position: absolute;
		justify-content: space-evenly;
	}	
}
